import { useState } from "react";
import { Redirect } from "react-router-dom";
import NavigationItem from "../../Navbar/NavigationItem/NavigationItem";

export default function SignOutNav() {
  const [signOut, setSignOut] = useState(false);

  function logout() {
    localStorage.removeItem('authToken');
    setSignOut(true);
  }

  if (signOut) {
    return <Redirect to="/" />
  }
  else {
    return <NavigationItem text="Sign Out" icon="fa-sign-out-alt" onClick={logout} />
  }
}