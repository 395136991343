import { useState, useEffect } from "react";

import * as api from "../../../modules/api";

export default function QaMetrics() {
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    if (metrics !== null) {
      const translateKeyToDisplayName = (key) => {
        switch (key) {
          case "avg_network_time": return "Average Network Time";
          case "max_network_time": return "Maximum Network Time";
          case "stddev_network_time": return "Network Standard Dev.";
          case "avg_response_time": return "Average Response Time";
          case "max_response_time": return "Maximum Response Time";
          case "stddev_response_time": return "Response Standard Dev.";
          default: return key;
        }
      };

      const graphMetrics = metrics
        .map(metric => ({ key: translateKeyToDisplayName(metric.key), values: metric.values }));

      const d3 = window.d3;

      const container = d3.select('#histogram');
      container.html("");

      const margin = { top: 20, right: 20, bottom: 30, left: 40 };
      const width = 800 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      const metricNames = graphMetrics.map(m => m.key);
      const periodNames = graphMetrics[0].values.map(v => v.period);

      const x0 = d3.scaleBand()
        .domain(metricNames)
        .rangeRound([0, width], .5)
        .padding([0.05]);

      const x1 = d3.scaleBand()
        .domain(periodNames)
        .rangeRound([0, x0.bandwidth()]);

      const maxy = d3.max(graphMetrics, metric => d3.max(metric.values, value => value.value));
      const yScale = d3.scaleLinear().domain([0, maxy]).rangeRound([height, 0]);

      const xAxis = d3.axisBottom().scale(x0).tickValues(graphMetrics.map(m => m.key));
      const yAxis = d3.axisLeft().scale(yScale);

      const orange1 =  d3.rgb("#FFA500");
      const orange2 = orange1.darker(0.4);
      const orange3 = orange1.darker(0.8);

      const barColors = d3.scaleOrdinal([orange1, orange2, orange3]);

      const svg = container
        .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", `translate(${margin.left}, ${margin.top})`);

      svg
        .append("g")
          .attr("class", "x axis")
          .attr("transform", `translate(0, ${height})`)
          .call(xAxis);

      svg
        .append("g")
          .attr("class", "y axis")
          .style('opacity','0')
          .call(yAxis)
        .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end")
          .style('font-weight','bold')
          .text("Value");

      svg.select('.y').transition().duration(500).delay(500).style('opacity','1');

      const slice = svg.selectAll(".slice")
        .data(graphMetrics)
        .enter().append("g")
          .attr("class", "g")
          .attr("transform", d =>`translate(${x0(d.key)},0)`);

      slice.selectAll("rect")
        .data(d => d.values)
        .enter().append("rect")
          .attr("x", d => x1(d.period))
          .attr("width", x1.bandwidth())
          //NOTE: The rectangles start at 0, so they can grow to their proper size.
          .attr("y", d => yScale(0))
          .attr("height", d => height - yScale(0))
          .style("fill", d => barColors(d.period))
          .on("mouseover", d => d3.select(this).style("fill", d3.rgb(barColors(d.period)).darker(2)))
          .on("mouseout", d => d3.select(this).style("fill", barColors(d.period)));

      slice.selectAll("rect")
        .transition()
        .delay(d => Math.random()*300)
        .duration(1000)
        .attr("y", d => yScale(d.value))
        .attr("height", d => height - yScale(d.value));

      const legend = svg.selectAll(".legend")
        .data(graphMetrics[0].values.map(d => d.period).reverse())
        .enter().append("g")
          .attr("class", "legend")
          .attr("transform", (d, i) => `translate(0, ${i * 20})`)
          .style("opacity","0");

      legend
        .append("rect")
          .attr("x", width - 18)
          .attr("width", 18)
          .attr("height", 18)
          .style("fill", d => barColors(d));

      legend
        .append("text")
          .attr("x", width - 24)
          .attr("y", 9)
          .attr("dy", ".35em")
          .style("text-anchor", "end")
          .text(d => d);

      legend.transition().duration(500).delay((d, i) => 800 + 100 * i).style("opacity","1");
    }
  }, [metrics]);

  useEffect(() => {
    async function getMetrics() {
      try {
        const response = await api.getQaMetrics();
        const metrics = response.body;
    
        let metricsList = Object.values(metrics);
    
        const graphData = Object.fromEntries(Object.keys(metricsList[0]).filter(k => k !== "period").map(k => ([k, []])));
    
        for (const entry of metricsList) {
          for (const key of Object.keys(graphData)) {
            graphData[key].push({
              period: entry.period,
              value: parseFloat(entry[key])
            });
          }
        }
    
        setMetrics(Object.entries(graphData).map(([k, v]) => ({ key: k, values: v })));
      }
      catch (fetchError) {
        console.error(fetchError);
      }
    }

    getMetrics();
  }, []);

  return (
    <div id="metrics-container">
      <div id="histogram">
      </div>
    </div>
  )
}
