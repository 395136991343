import { Link } from "react-router-dom";

import "./navigation_item.scss";

export default function NavigationItem(props) {
  let iconDisplay;
  if (props.updates && props.updates > 0) {
    iconDisplay = <span class="badge rounded-pill bg-danger text-light">{props.updates}</span>
  }
  else {
    iconDisplay = <i class={`fas ${props.icon}`}></i>
  }

  const coreMarkup = (
    <div className={`aim-nav-link ${props.active ? 'active-aim-nav-link' : ''} clickable`} onClick={props.onClick}>
      {iconDisplay}
      <span className="aim-nav-link-label">{props.text}</span>
    </div>
  )

  if (props.href) {
    return (
      <Link className="nav-item-link-tag" to={`${props.href}`}>
        {coreMarkup}
      </Link>
    );
  }
  else {
    return coreMarkup;
  }
}
