import React from "react";

import "./project_settings.scss";

export default function SettingsTextArea({ value: initialValue, rowIndex, updateCallback }) {
  const [value, setValue] = React.useState(initialValue);
  const [changed, setChanged] = React.useState(false);

  const onChange = e => {
    setValue(e.target.value)
    setChanged(true)
  };

  // Only update the external data when the input is deselected
  const onClick = () => {
    updateCallback({rowIndex, value});
    setChanged(false);
  };

  // If the initialValue is changed externally, update this cell
  React.useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <div className="project-settings-value">
      <div style={{display:'flex'}}>
        <textarea
          value={value}
          onChange={onChange}
          rows={2} cols={50}
          style={{'minHeight': '2rem', resize:'both'}} />
          {
            (() => {
              if (changed) {
                return (<div className="settings-update-button" onClick={onClick}>Update</div>);
              } else {
                return (<div className="inactive settings-update-button">Update</div>);
              }
            })()
          }
      </div>
    </div>
  );
}
