import React from "react";
import * as api from "../../../../modules/api";

import "./bypass_eligibility_editor.scss";

export default function BypassEligibilityEditor(props) {
  const alphebetize = (list) => {
    list.sort((a, b) => a.displayName.localeCompare(b.displayName));
    return list;
  };

  const [eligibleFields, setEligibleFields] = React.useState(alphebetize(props.bypassFields.filter(f => f.value === 'TRUE')));
  const [ineligibleFields, setIneligibleFields] = React.useState(alphebetize(props.bypassFields.filter(f => f.value === 'FALSE')));

  const [selectedEligibles, setSelectedEligibles] = React.useState([]);
  const [selectedIneligibles, setSelectedIneligibles] = React.useState([]);

  // If the fields change externally, update
  React.useEffect(() => {
    setEligibleFields(alphebetize(props.bypassFields.filter(f => f.value === 'TRUE')));
    setIneligibleFields(alphebetize(props.bypassFields.filter(f => f.value === 'FALSE')));
  }, [props]);


  const saveFields = (list) => {
    const saveField = async ({ item, value }) => {
      try {
        await api.updateSetting({ list_name: 'eligible_for_bypass', item, value });
      } catch (err)  {
        console.err(`failed to save setting ${err}`)
      }
    };

    list.forEach(field => {
      saveField({ item: field.id, value: field.value });
    });
  };

  const makeEligible = () => {
    selectedIneligibles.forEach(item => item.value = 'TRUE');

    const newEligibleFields = eligibleFields.concat(selectedIneligibles);
    const newIneligibleFields = ineligibleFields.filter(f => !selectedIneligibles.includes(f));

    saveFields(selectedIneligibles);

    setEligibleFields(alphebetize(newEligibleFields));
    setIneligibleFields(alphebetize(newIneligibleFields));
    setSelectedIneligibles([]);
  };

  const makeIneligible = () => {
    selectedEligibles.forEach(item => item.value = 'FALSE');

    const newEligibleFields = eligibleFields.filter(f => !selectedEligibles.includes(f));
    const newIneligibleFields = ineligibleFields.concat(selectedEligibles);

    saveFields(selectedEligibles);

    setEligibleFields(alphebetize(newEligibleFields));
    setIneligibleFields(alphebetize(newIneligibleFields));
    setSelectedEligibles([]);
  };

  const makeAllEligible = () => {
    ineligibleFields.forEach(item => item.value = 'true');

    const newEligibleFields = eligibleFields.concat(ineligibleFields);
    const newIneligibleFields = [];

    saveFields(ineligibleFields);

    setEligibleFields(alphebetize(newEligibleFields));
    setIneligibleFields(alphebetize(newIneligibleFields));
    setSelectedIneligibles([]);
  };

  const makeAllIneligible = () => {
    eligibleFields.forEach(item => item.value = 'false');

    const newEligibleFields = [];
    const newIneligibleFields = ineligibleFields.concat(eligibleFields);

    saveFields(eligibleFields);

    setEligibleFields(alphebetize(newEligibleFields));
    setIneligibleFields(alphebetize(newIneligibleFields));
    setSelectedEligibles([]);
  };

  const toggleSelect = (field) => {
    if (field.value === 'TRUE') {
      if (!selectedEligibles.includes(field)) {
        setSelectedEligibles([...selectedEligibles, field]);
      } else {
        setSelectedEligibles(selectedEligibles.filter(s => s !== field));
      }
    } else {
      if (!selectedIneligibles.includes(field)) {
        setSelectedIneligibles([...selectedIneligibles, field]);
      } else {
        setSelectedIneligibles(selectedIneligibles.filter(s => s !== field));
      }
    }
  };

  return (
    <div id="bypass-eligibility-editor-container">
      <div id="bypass-container-left">
        <span className='bypass-container-header'>Not Eligible</span>
        <div className="bypass-eligibility-editor-item-container">
          { ineligibleFields.map(field => {
              let className = "selectable bypass-eligibility-editor-item";

              if (selectedIneligibles.includes(field)) {
                className += " selected";
              }

              return (
                <div
                  key={field.displayName}
                  className={className}
                  onClick={() => toggleSelect(field)}>
                  {field.displayName}
                </div>
              );
            }
          ) }
        </div>
      </div>
      <div id="bypass-container-middle">
        <div className="bypass-button-group">
          <div
            className="move-item-button"
            onClick={makeEligible}>
            <i class="fas fa-angle-right" aria-hidden="true"></i>
          </div>
          <div
            className="move-item-button"
            onClick={makeIneligible}>
            <i class="fas fa-angle-left" aria-hidden="true"></i>
          </div>
        </div>
        <div className="bypass-button-group">
          <div
            className="move-item-button"
            onClick={makeAllEligible}>
            <i class="fas fa-angle-double-right" aria-hidden="true"></i>
          </div>
          <div
            className="move-item-button"
            onClick={makeAllIneligible}>
            <i class="fas fa-angle-double-left" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div id="bypass-container-right">
        <span className='bypass-container-header'>Eligible</span>
        <div className="bypass-eligibility-editor-item-container">
          { eligibleFields.map(field => {
              let className = "selectable bypass-eligibility-editor-item";

              if (selectedEligibles.includes(field)) {
                className += " selected";
              }

              return (
                <div
                  key={field.displayName}
                  className={className}
                  onClick={() => toggleSelect(field)}>
                  {field.displayName}
                </div>
              );
            }
          ) }
        </div>
      </div>
    </div>
  );
}
