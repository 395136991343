export default function FileUploadRenderer(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  let displayedValue;
  if (cellValue) {
    const id = typeof cellValue === 'object' ? cellValue.id : cellValue;
    const attachment = props.attachments.find(attachment => attachment.upload_id === id);
    displayedValue = attachment && attachment.filename ? attachment.filename : '';
  }
  else {
    displayedValue = '';
  }

  if (props.data.validationErrors && props.data.validationErrors[props.colDef.field]) {
    return (
      <div className="cell-with-error">
        <span><i className="fas fa-exclamation-circle"  style={{ color: '#ff000099' }}></i> {displayedValue}</span>
        <span className="cell-error-message">{props.data.validationErrors[props.colDef.field]}</span>
      </div>
    );
  }
  else {
    return (
      <div>{displayedValue}</div>
    );
  }
};
