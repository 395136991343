import "./default_cell_renderer.scss";

export default function DefaultCellRenderer(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  // if we have an error associated with this cell- we'll display it
  if (
    !props.data.deleted &&
    props.data.status !== 'committed' &&
    props.data.validationErrors &&
    props.data.validationErrors[props.colDef.field]) {

    return (
      <div className="cell-with-error">
        <span><i className="fas fa-exclamation-circle"  style={{ color: '#ff000099' }}></i> {cellValue}</span>
        <span className="cell-error-message">{props.data.validationErrors[props.colDef.field]}</span>
      </div>
    );
  }
  else {
    return <span>{cellValue}</span>
  }
};
