import React from "react";

import "./project_settings.scss";

export default function SettingsDropdown({ value: initialValue, options=["NO OPTIONS SET"], rowIndex, picklistKey, updateCallback }) {
  const [value, setValue] = React.useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
    updateCallback({rowIndex, picklistKey, value: e.target.value});
  }

  // If the initialValue is changed externally, update this cell
  React.useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <div className="project-settings-value">
      <select defaultValue={value} style={{padding: "1px 2px"}} onChange={onChange}>
        {options.map((opt, i) => <option key={`${rowIndex}-${opt}`} value={opt}>{opt}</option>)}
      </select>
    </div>
  );
}
