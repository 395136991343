import { Switch, Route, useRouteMatch } from "react-router-dom";

import NavigationItem from "../../components/Navbar/NavigationItem/NavigationItem";
import AccountsReport from "../../components/Reviewer/AccountsReport/AccountsReport";
import ContactsReport from "../../components/Reviewer/ContactsReport/ContactsReport";
import QaMetrics from "../../components/Reviewer/QaMetrics/QaMetrics";
import SignOutNav from "../../components/shared/SignOutNav/SignOutNav";
import EditAccounts from "../../components/Reviewer/EditAccounts/EditAccounts";
import EditContacts from "../../components/Reviewer/EditContacts/EditContacts";

import "./reviewer.scss";
import ProjectDashboard from "../../components/shared/ProjectDashboard/ProjectDashboard";
import ProjectSettings from "../../components/Reviewer/ProjectSettings/ProjectSettings";
import Header from "../../components/Header/Header";

export default function Reviewer(props) {
  const match = useRouteMatch();

  return (
    <div id="reviewer-container">
      <nav id="left-nav-reviewer">
        <div id="corner-logo">
          <h1 className="aim-logo">AIM</h1>
          <span className="aim-logo-subtext">Advisor Intake Manager</span>
        </div>
        <div id="nav-link-container">
          { props.flags.dashboard ? <NavigationItem icon="fa-chart-line" text="Project Dashboard" href="/reviewer" /> : undefined }
          { props.flags.settings ? <NavigationItem icon="fa-cog" text="Project Settings" href="/reviewer/settings" /> : undefined }
          <NavigationItem icon="fa-user-circle" text="Contacts" href="/reviewer/editcontacts" />
          <NavigationItem icon="fa-wallet" text="Financial Accounts" href="/reviewer/editagreements" />
          { props.flags.contactsCasReport ? <NavigationItem icon="fa-file-alt" text="Contacts CAS Report" href="/reviewer/contactsreport" /> : undefined }
          { props.flags.agreementsCasReport ? <NavigationItem icon="fa-file-alt" text="Financial Accounts CAS Report" href="/reviewer/accountsreport" /> : undefined } 
          { props.flags.metrics ? <NavigationItem icon="fa-chart-bar" text="View QA Metrics" href="/reviewer/metrics" /> : undefined }
          <hr />
          <SignOutNav />
        </div>
        <div id="powered-by-ht-logo-container">
          <img id="powered-by-ht-logo" src="/images/PoweredByHT.png" alt="aim logo"/>
        </div>
      </nav>
      <div id="reviewer-content-body">
        <Header />
        <Switch>
          <Route path={`${match.url}/editagreements/advisory`}>
            <EditAccounts flags={props.flags} tab="advisory" />
          </Route>
          <Route path={`${match.url}/editagreements/brokerage`}>
            <EditAccounts flags={props.flags} tab="brokerage" />
          </Route>
          <Route path={`${match.url}/editagreements/commissionable`}>
            <EditAccounts flags={props.flags} tab="commissionable" />
          </Route>
          <Route path={`${match.url}/editagreements/profservices`}>
            <EditAccounts flags={props.flags} tab="profservices" />
          </Route>
          <Route path={`${match.url}/editagreements`}>
            <EditAccounts flags={props.flags} tab="advisory" />
          </Route>
          <Route path={`${match.url}/settings`}>
            { props.flags.settings ? <ProjectSettings /> : undefined }
          </Route>
          <Route path={`${match.url}/editcontacts`}>
            <EditContacts flags={props.flags} />
          </Route>
          <Route path={`${match.url}/accountsreport`}>
            { props.flags.agreementsCasReport ? <AccountsReport /> : undefined }
          </Route>
          <Route path={`${match.url}/contactsreport`}>
            { props.flags.contactsCasReport ? <ContactsReport /> : undefined }
          </Route>
          <Route path={`${match.url}/metrics`}>
            { props.flags.metrics ? <QaMetrics /> : undefined }
          </Route>
          <Route path={`${match.url}`}>
            { props.flags.dashboard ? <ProjectDashboard /> : undefined }
          </Route>
        </Switch>
      </div>
    </div>
  );
}
