import { useMemo } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

import "./upload_history_table.scss";

export default function UploadHistoryTable(props) {
  const columns = useMemo(() => [
    {
      Header: 'Uploaded Date',
      accessor: 'captured',
    },
    {
      Header: 'Uploaded By',
      accessor: 'uploader',
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Filename',
      accessor: 'filename',
    },
    {
      Header: '# Rows',
      accessor: 'rows',
    },
    {
      Header: '# Columns',
      accessor: 'columns',
    },
    {
      Header: 'Notes',
      accessor: 'issues',
    },
  ], []);

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const defaultColumn = useMemo(() => ({
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter,
  }), []);

  const filterTypes = useMemo(() => ({
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true
      })
    },
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // replaces rows

    // pagination related props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns, data: Array.isArray(props.data) ? props.data : [],
    defaultColumn,
    filterTypes,
    initialState: { pageIndex: 0 }
  },
  useFilters,
  useSortBy,
  usePagination);

  return (
    <>
      {/* Table controls */}
      <div className="upload-history-table-controls">
        <div>
          <span className="text-muted upload-history-table-label">{props.label}</span>
        </div>
        <div className="pagination">
          <div className="page-control" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <i className="fas fa-angle-double-left"></i>
          </div>
          <div className="page-control" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <i className="fas fa-angle-left"></i>
          </div>
          <div className="page-control" onClick={() => nextPage()} disabled={!canNextPage}>
            <i className="fas fa-angle-right"></i>
          </div>
          <div className="page-control" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <i className="fas fa-angle-double-right"></i>
          </div>
          <span>
            Page
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '50px' }}
            />
          </span>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}>
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* The table itself */}
      <table id="upload-history-table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} id={`upload-history-table-header-${column.id}`} className="upload-history-table-header">
                  <span>
                    {column.render('Header')}
                  </span>
                  {/* Add a sort direction indicator */}
                  <span className="sort-controls">
                    {column.isSorted
                    ? column.isSortedDesc
                      ? <i className="fas fa-sort-down"></i>
                      : <i className="fas fa-sort-up"></i>
                    : <i className="fas fa-sort"></i>}
                  </span>
                </th>
              ))}
            </tr>
          ))}

          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="upload-history-table-filter-header">
                  <div className="record-table-header-filter">
                    {column.render('Filter')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)

            return (
              <tr
                className={`upload-history-table-row clickable`}
                {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className="upload-history-table-cell">
                        {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  );
}
