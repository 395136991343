import "./table_button.scss";

export default function TableButton({ref, icon=null, text='', onClick: providedOnClick=() => {}, disabled=false}) {
  let onClick = providedOnClick;
  let className = "editor-control-button"

  if (disabled === true) {
    onClick = () => {};
    className += " disabled";
  }

  let iconMarkup = null;
  if (icon != null) {
    iconMarkup = <i className={`fas ${icon}`}></i>;
  }

  return (
    <div ref={ref} className={className} onClick={onClick}>
      {iconMarkup}
      <span>{text}</span>
    </div>
  );
}
