import { useState } from "react";
import { usePopper } from 'react-popper';

import "./select_page_size.scss";

export default function SelectPageSize(props) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { update, styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement }
      },
      {
        name: 'offset',
        options: { offset: [0, 10] }
      }
    ],
  });

  function showPageSizeTooltip() {
    props.setActive();
    update();
  }

  const pageSizes = [10, 15, 25, 50, 100];

  return (
    <>
      <div
        id="select-page-size-button"
        className="editor-control-button"
        ref={setReferenceElement}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          showPageSizeTooltip();
        }}>
        <i className={`fas fa-ellipsis-v`}></i>
        <span>Page Size ({ props.pageSize })</span>
      </div>

      <div
        id="page-size-control"
        ref={setPopperElement}
        style={styles.popper}
        className={`${props.active ? '' : 'invisible'}`} {...attributes.popper}>
        <div id="page-size-control-select">
          {pageSizes.map((size) => {
            return (
              <span
                className={`page-size-control-option ${ props.pageSize === size ? 'selected-page-size' : ''}`}
                onClick={() => { props.setPageSize(size); showPageSizeTooltip(); }}>{size} rows</span>
            )
          })}
        </div>
        <div ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  );
}
