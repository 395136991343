import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./date_picker_cell.scss";

const DatePickerCell = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const refContainer = useRef(null);

  useEffect(() => {
    // focus on the date picker input
    setTimeout(() => refContainer.current.children[0].children[0].children[0].focus());
  }, []);

  const onChange = (date) => {
    try {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const stringified = `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
      setValue(stringified);
    }
    catch (error) {
      console.error(error);
    }
  }

  let processedValue;
  if (value) {
    const [year, month, day] = value.split('-');
    processedValue = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }
  else {
    processedValue = null;
  }

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isPopup() {
        return true;
      }
    };
  });

  return (
    <div className="table-date-container" ref={refContainer}>
      <DatePicker
        className="table-date-input"
        selected={processedValue}
        onChange={onChange}
        dateFormat={"yyyy-MM-dd"} />
    </div>
  );
});

export default DatePickerCell;
