import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import * as api from "../../modules/api";
import "./root.scss";

export default function Root(props) {
  const [loginPath, setLoginPath] = useState('');

  useEffect(() => {
    async function getLoginPath() {
      const paths = await api.getPaths();
      setLoginPath(paths.logoutRedirect);
    }

    getLoginPath();
  }, []);

  let quickNav;
  if (props.flags.noauth) {
    quickNav = (
      <>
        <a href="/setup?role=reviewer">Go to reviewer page</a>
        <span> | </span>
        <a href="/setup">Go to advisor page</a>
      </>
    );
  }

  return (
    <div id="root-nav">
      <div id="root-nav-header">
        <img src="/images/Hightower-color-300-by-50.png" alt="Hightower logo" />
        { quickNav }
      </div>
      <div id="root-nav-body">
        <div id="root-nav-body-image">
          <img id="entrance-img" src="/images/ht_welcome.png" alt="Entrance" />
        </div>
        <div id="root-nav-body-content">
          <p id="root-nav-title">Welcome to Hightower</p>
          <p>At Hightower we want to ensure a smooth transition for both you and your data. The first step in that process is using Hightower AIM.</p>
          <div id="root-nav-button-container">
            <a className="root-button" href={loginPath}>Log into AIM</a>
            <Link className="root-button grey-root-button">Contact Us For Help</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
