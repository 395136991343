import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./promote_modal.scss";

export default function PromoteModal(props) {
  let plural = 'record';
  if (props.recordCount > 1) {
    plural = 'records';
  }

  return (
    <AimModal show={props.show}>
      <div id="promote-modal-header">
        <span>Promote</span>
      </div>
      <div id="promote-modal-body">
        <p>You're about to promote {props.recordCount} {plural} to the Hightower team for review. Only valid records will be promoted. Are you sure you want to continue?</p>
      </div>
      <div id="promote-modal-footer">
      <TableButton icon="fa-file-upload" text="Promote" onClick={props.onPromote}/>
        <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
      </div>
    </AimModal>
  )
}
