import { useState, useEffect } from "react";

import RecordTable from "../../shared/RecordTable/RecordTable";
import LoadingBox from "../../shared/LoadingBox/LoadingBox";
import * as extract from "../../../modules/extractFromModel";
import * as api from "../../../modules/api";

import "./edit_contacts.scss";

export default function EditContacts(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [modelDefs, setModelDefs] = useState(null);

  useEffect(() => {
    async function getContacts() {
      try {
        const modelDefs = await extract.getModelClients();
        setModelDefs(modelDefs);
        
        const clients = await api.getContacts();

        setContacts(clients.filter(contact => contact.status === 'promoted' || contact.status === 'committed' || contact.status === 'demoted'));
        setLoading(false);
      }
      catch (fetchError) {
        console.error(fetchError);
        setError('Error retrieving contact information');
      }
    }

    getContacts();
  }, []);

  let bodyMarkup;
  if (error) {
    bodyMarkup = (
      <div>
        {error}
      </div>
    )
  }
  else if (loading) {
    bodyMarkup = <LoadingBox subMessage="This may take up to a minute for many records." />
  }
  else {
    bodyMarkup = (
      <RecordTable
        canAddRecords={false}
        canDeleteRecords={true}
        canCommitRecords={true}
        canPromoteRecords={false}
        canEditPromotedRecords={true}
        canDemoteRecords={true}
        canEditDemotedRecords={true}
        canBulkEditRecords={false} // there are no valid "bulk editable" fields for contacts

        isAccount={false}
        isReviewer={true}
        data={contacts}
        modelDefs={modelDefs}
        flags={props.flags} />
    );
  }

  return (
    <div id="reviewer-contacts-container">
      {bodyMarkup}
    </div>
  );
}
