import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./assign_caa_number_modal.scss";

export default function AssignCaaNumberModal(props) {
  let message = '';
  let buttonsMarkup = undefined;

  if (props.recordCount > 0) {
    let plural = (props.recordCount > 1) ? 'records their CAA numbers' : 'record a CAA number';
    message = `You're about to assign ${props.recordCount} ${plural}. Are you sure you want to continue?`;

    buttonsMarkup = (
      <div id="assign-caa-modal-footer">
        <TableButton icon="fa-check-circle" text="Continue" onClick={props.onContinue}/>
        <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
      </div>
    )
  }

  return (
    <AimModal show={props.show}>
      <div id="assign-caa-modal-header">
        <span>Assign CAA Numbers</span>
      </div>
      <div id="assign-caa-modal-body">
        <p>{message}</p>
      </div>
      {buttonsMarkup}
    </AimModal>
  )
}