import React from "react";

import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./demote_modal.scss";

export default function DemoteModal(props) {
  const [demoteReason, setDemoteReason] = React.useState('');

  let plural = 'record';
  if (props.recordCount > 1) {
    plural = 'records';
  }

  const onCancel = (e) => {
    props.onCancel();
    setDemoteReason('');
  };

  const onDemote = (e) => {
    props.onDemote(demoteReason.trim());
    setDemoteReason('');
  };


  return (
    <AimModal show={props.show}>
      <div id="demote-modal-header">
        <span>Demote</span>
      </div>
      <div id="demote-modal-body">
        <p>Reason for demotion:</p>
        <textarea
            id="demote-modal-textarea"
            value={demoteReason}
            maxLength={250}
            onChange={(e) => setDemoteReason(e.target.value)}/>
      </div>
      <p>You're about to demote {props.recordCount} {plural}. Are you sure you want to continue?</p>
      <div id="demote-modal-footer">
        <TableButton icon="fa-arrow-alt-circle-down" text="Demote" onClick={onDemote} disabled={(demoteReason.length < 1)}/>
        <TableButton icon="fa-times-circle" text="Cancel" onClick={onCancel}/>
      </div>
    </AimModal>
  )
}
