import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Projects from "./pages/Projects/Projects";
import Setup from "./pages/Setup/Setup";
import Reviewer from "./pages/Reviewer/Reviewer";
import './App.scss';
import LoadingBox from "./components/shared/LoadingBox/LoadingBox";
import { getFlags } from "./modules/getFeatureFlags";
import Root from "./pages/Root/Root";
import IdleTimer from "./IdleTimer";

/*
  Entrypoint for entire application
*/
export default function App() {
  const [flags, setFlags] = useState(null);

  useEffect(() => {
    async function loadFlags() {
      const payload = await getFlags();
      setFlags(payload);
    }

    loadFlags();
  }, []);

  if (flags === null) {
    return <LoadingBox />
  }

  return (
    <div className="App">
      <BrowserRouter>
        <IdleTimer />
        <Switch>
          <Route path="/setup">
            <Setup />
          </Route>
          <Route path="/advisor">
            <Projects flags={flags} />
          </Route>
          <Route path="/reviewer">
            <Reviewer flags={flags} />
          </Route>
          <Route path="/">
            <Root flags={flags} />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
