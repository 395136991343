import React from "react";

import "./project_settings.scss";

export default function SettingsInput({ value: initialValue, rowIndex, updateCallback }) {
  const [value, setValue] = React.useState(initialValue);

  const onChange = e => setValue(e.target.value);

  // Only update the external data when the input is deselected
  const onBlur = (e) => updateCallback({rowIndex, value: e.target.value});

  // If the initialValue is changed externally, update this cell
  React.useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <div className="project-settings-value">
      <input value={value} onChange={onChange} onBlur={onBlur} size={40} />
    </div>
  );
}
