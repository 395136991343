import "./status_cell_renderer.scss";

export default function StatusCellRenderer(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  let statusMarkup;

  // first and foremost: display transition statuses
  if (props.data.transitionStatus === 'updating') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-circle-notch fa-spin"></i> updating...</span>;
  }
  else if (props.data.transitionStatus === 'deleting') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-circle-notch fa-spin"></i> deleting...</span>;
  }
  else if (props.data.transitionStatus === 'restoring') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-circle-notch fa-spin"></i> restoring...</span>;
  }
  else if (props.data.transitionStatus === 'demoting') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-circle-notch fa-spin"></i> demoting...</span>;
  }
  else if (props.data.transitionStatus === 'promoting') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-circle-notch fa-spin"></i> promoting...</span>;
  }
  else if (props.data.transitionStatus === 'committing') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-circle-notch fa-spin"></i> submitting...</span>;
  }
  // if it's deleted always display a "deleted" status
  else if (props.data.deleted) {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-ban"></i> deleted</span>;
  }
  else if ((props.data.duplicate !== undefined && props.data.duplicate !== "no") ||
    (props.data.frontDuplicate !== undefined && (props.data.frontDuplicate === true || props.data.frontDuplicate === 'true'))) {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-exclamation-circle" style={{ color: '#0000ff99' }}></i> duplicate</span>;
  }
  // if it's invalid always display an "invalid" status
  else if (props.data.invalid === true || props.data.invalid === 'true') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-exclamation-circle" style={{ color: '#ff000099' }}></i> invalid</span>;
  }
  // if invalid is EXPLICITLY FALSE then that indicates that the row is ready for promotion
  else if ((props.data.invalid === false || props.data.invalid === 'false') && (props.data.status === undefined)) {
    statusMarkup = <span className="status-icon-cell">*can promote</span>;
  }
  // if it's a duplicate always display a "duplicate" status
  // otherwise, display the actual status!
  else if (cellValue === 'promoted') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-arrow-alt-circle-up"></i> promoted</span>;
  }
  else if (cellValue === 'demoted') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-arrow-alt-circle-down"></i> demoted</span>;
  }
  else if (cellValue === 'committed') {
    statusMarkup = <span className="status-icon-cell"><i className="fas fa-check-circle"></i> completed</span>;
  }
  else {
    statusMarkup = <span className="status-icon-cell">{cellValue}</span>;
  }

  return statusMarkup;
}
