import CountUp from "react-countup";

export default function BigNumberDisplay(props) {
  let numberMarkup;
  if (props.loading) {
    numberMarkup = (
      <i class="fas fa-circle-notch fa-spin"></i>
    )
  }
  else {
    numberMarkup = (
      <CountUp end={props.count} duration={2} />
    )
  }

  return (
    <div className="circle-chart-container">
      <div className="big-number">
        {numberMarkup}
      </div>
      <span className="text-muted chart-label"><i className={`fas ${props.icon}`}></i> {props.text}</span>
    </div>
  )
}
