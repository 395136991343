import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./delete_modal.scss";

export default function DeleteModal(props) {
  let plural = 'record';
  if (props.recordCount > 1) {
    plural = 'records';
  }

  return (
    <AimModal show={props.show}>
      <div id="delete-modal-header">
        <span>Delete</span>
      </div>
      <div id="delete-modal-body">
        <p>You're about to delete {props.recordCount} {plural}. Deleted records can be restored later. Are you sure you want to continue?</p>
      </div>
      <div id="delete-modal-footer">
      <TableButton icon="fa-trash-alt" text="Delete" onClick={props.onDelete}/>
      <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
      </div>
    </AimModal>
  )
}
