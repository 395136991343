import "./view_notes_button.scss";

export default function ViewNotesButton({ref, text='', onClick: providedOnClick=() => {}, disabled=false}) {
  let onClick = providedOnClick;
  let className = "view-notes-button"

  if (disabled === true) {
    onClick = () => {};
    className += " disabled";
  }

  return (
    <div ref={ref} className={className} onClick={onClick}>
      <span>{text}</span>
    </div>
  );
}
