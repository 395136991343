import { useState, useEffect } from "react";

import * as api from "../../../modules/api";
import ContactsReportTable from "./ContactsReportTable/ContactsReportTable";
import LoadingBox from "../../shared/LoadingBox/LoadingBox";

export default function ContactsReport() {
  const [loading, setLoading] = useState(false);
  const [contactsReportData, setContactsReportData] = useState([]);

  useEffect(() => {
    async function getContactsReport() {
      setLoading(true);
      try {
        const contactData = await api.getContactsReport();
        setContactsReportData(contactData.body);
      }
      catch (error) {
        console.error(error);
      }
      setLoading(false);
    }

    getContactsReport();
  }, []);

  if (loading) {
    return (
      <LoadingBox />
    )
  }
  else {
    return (
      <div id="report-tables-container">
        <ContactsReportTable data={contactsReportData}/>
      </div>
    );
  }
}
