import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./commit_modal.scss";

export default function CommitModal(props) {
  let plural = 'record';
  if (props.recordCount > 1) {
    plural = 'records';
  }

  let willAssignCaa = props.isAccounts ? 'All records missing a CAA number will be assigned one.' : '';

  return (
    <AimModal show={props.show}>
      <div id="commit-modal-header">
        <span>Submit to CAS</span>
      </div>
      <div id="commit-modal-body">
        <p>You're about to submit {props.recordCount} {plural} to CAS. {willAssignCaa} This action cannot be undone. Are you sure you want to continue?</p>
      </div>
      <div id="commit-modal-footer">
        <TableButton icon="fa-check-circle" text="Submit to CAS" onClick={props.onCommit}/>
        <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
      </div>
    </AimModal>
  )
}
