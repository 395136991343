import FileGutterSquare from "./FileGutterSquare/FileGutterSquare";

export default function FileGutter(props) {
  let markup;
  if (props.fileList.length > 0) {
    markup = (
      <div id="drop-zone-file-list">
        {props.fileList.map((fileName) => <FileGutterSquare key={fileName} filename={fileName}/>)}
      </div>
    );
  }
  else {
    markup = (
      <div id="drop-zone-file-list">
        <span>No files uploaded yet.</span>
      </div>
    );
  }

  return markup;
}
