import { useEffect } from "react";

/*

    Accepts:
      - red
      - yellow
      - green

    Note, this component uses the front-end library D3 quite heavily

*/
export default function StatsDisplay(props) {
  useEffect(() => {
    let total = props.red + props.yellow + props.green + props.blue;

    let data = [
      { id: 'red', percent: props.red / total, fill: 'url(#redGradient)', textFill: '#3f3f3f', minX: 0, maxOffset: 120 },
      { id: 'yellow', percent: props.yellow / total, fill: '#ccc', textFill: '#3f3f3f', minX: 30, maxOffset: 90 },
      { id: 'green', percent: props.green / total, fill: 'url(#greenGradient)', textFill: '#3f3f3f', minX: 60, maxOffset: 60 },
      { id: 'blue', percent: props.blue / total, fill: '#0000ff', textFill: '#3f3f3f', minX: 90, maxOffset: 30 },
    ];

    // Pre-calculate a basis percentage for each data point.
    // NOTE: Display order of the data can not change after this step!
    data.reduce((acc, data) => {
      data.basis = acc;
      return acc + data.percent;
    }, 0);

    const width = props.width;

    const svg = window.d3.select(`#${props.svgId}`);
    const ref = svg.selectAll("rect")
        .data(data, function(d) { return d.id; });

    /*

        Construct our "data bars" or coloured rectangles

    */
    ref.enter().append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 10)
      .style('fill', function(d) { return d.fill })
      .transition()
        .ease(window.d3.easeExp)
        .duration(2000)
        .attr("x", function(d) { return d.basis * width })
        .attr("width", function(d) { return Math.max((d.percent * width - 3), 1) });

    /*

        Add text to make the amount more explicit

    */
    ref.enter().append('text')
      .attr("x", 0)
      .attr("y", 27)
      .text(function(d) { return `${(d.percent * 100).toFixed(0)}%` })
      .style('fill', function(d) { return d.textFill })
      .style('font-weight', 'bold')
      .transition()
        .ease(window.d3.easeExp)
        .duration(2000)
        .attr("x", function(d, i) { return Math.min(Math.max(d.basis * width, d.minX), props.width - d.maxOffset ) })
  });

  return (
      <svg id={props.svgId} width={props.width} height="30">
        <defs>
          <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "#db2929", stopOpacity: "1"}} />
            <stop offset="100%" style={{ stopColor: "#971620", stopOpacity: "1" }} />
          </linearGradient>

          <linearGradient id="greenGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "#1d7f13", stopOpacity:"1" }} />
            <stop offset="100%" style={{ stopColor: "#28b121", stopOpacity:"1" }} />
          </linearGradient>
        </defs>
      </svg>
  );
}
