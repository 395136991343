import { useState } from 'react';
import { usePopper } from 'react-popper';
import { matchSorter } from "match-sorter";

import "./freeze_columns_button.scss";

export default function FreezeColumnsButton(props) {
  const [columnFilter, setColumnFilter] = useState('');
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { update, styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement }
      },
      {
        name: 'offset',
        options: { offset: [0, 10] }
      }
    ],
  });

  function showFreezeColumnsTooltip() {
    props.setActive();
    update();
  }

  function onChange(id) {
    const shallowObj = { ...props.frozenColumnIds };
    shallowObj[id] = !props.frozenColumnIds[id];
    props.setFrozenColumnIds(shallowObj);
  }

  const freezeColumnFiltered = matchSorter(props.allColumns.map(column => column.getLabel()), columnFilter);
  
  function clear() {
    props.setFrozenColumnIds({});
  }
  
  let clearButtonMarkup;
  if (Object.values(props.frozenColumnIds).filter(value => value).length >= 1) {
    clearButtonMarkup = (
      <div id="frozen-column-clear" onClick={clear}>
        <span>Reset Selection</span>
      </div>
    );
  }

  return (
    <>
      <div
        id="freeze-columns-button"
        className="editor-control-button"
        ref={setReferenceElement}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          showFreezeColumnsTooltip();
        }}>
        <i className={`fas fa-columns`}></i>
        <span>Freeze Columns</span>
      </div>

      <div
        id="freeze-control"
        ref={setPopperElement}
        style={styles.popper}
        className={`${props.active ? '' : 'invisible'}`}
        {...attributes.popper}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}>
        <input id="freeze-control-input" type="text" placeholder="search columns..." value={columnFilter} onChange={event => setColumnFilter(event.target.value)}/>
        {clearButtonMarkup}
        <div id="freeze-control-select-container">
          {props.allColumns.map((column) => {
            return (
              <div key={column.getId()} className={`${freezeColumnFiltered.find(name => name === column.getLabel()) ? 'field-control-select' : 'invisible'}`}>
                <input type="checkbox" checked={props.frozenColumnIds[column.getId()]} onChange={() => { onChange(column.getId()) }} />
                <span>{column.getLabel().substring(0, 24)}</span>
              </div>
            )
          })}
        </div>
        <div ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  )
}
