import React from "react";

import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./reviewer_feedback_modal.scss";

export default function ReviewerFeedbackModal(props) {
  return (
    <AimModal show={props.show}>
      <div id="reviewer-feedback-modal-header">
        <span>Reviewer Feedback</span>
      </div>
      <div id="reviewer-feedback-modal-body">
        <p>{props.text}</p>
      </div>
      <div id="reviewer-feedback-modal-footer">
        <TableButton text="Close" onClick={props.onClose}/>
      </div>
    </AimModal>
  )
}
