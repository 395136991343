import ViewNotesButton from "./ViewNotesButton/ViewNotesButton";

import "./feedback_cell_renderer.scss";

export default function FeedbackCellRenderer({displayModal, props}) {
  const reviewerNotes = props.data.reviewerNotes;

  const onClick = () => {
    displayModal(reviewerNotes);
  };

  let cellMarkup = null;
  if (reviewerNotes?.length > 0) {
    cellMarkup = (
      <div className="view-notes-cell">
        <ViewNotesButton text="View" onClick={onClick} />
      </div>
    );
  }

  return cellMarkup;
}
