import { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import StatsDisplay from "../shared/StatsDisplay/StatsDisplay";

import * as api from "../../modules/api";
import "./header.scss";

export default function Header() {
  const [stats, setStats] = useState({ red: 0, yellow: 0, green: 0});
  const [title, setTitle] = useState('');

  useEffect(() => {
    const getContacts = async () => (await api.getContacts()).body;
    const getAccounts = async () => (await api.getAccounts()).body;

    async function computeStats() {
      const stats = { red: 0, yellow: 0, green: 0, blue: 0 };

      const accumulate = (acc, elem) => {
        switch (elem.status) {
          case 'invalid': acc.red += 1; break;
          case 'promoted': acc.green += 1; break;
          case 'committed': acc.blue += 1; break;
          case 'deleted': break;
          default: acc.yellow += 1; break;
        }

        return acc;
      }

      try {
        await Promise.all([
          getContacts().then(contacts => (contacts || []).reduce(accumulate, stats)),
          getAccounts().then(accounts => (accounts || []).reduce(accumulate, stats)),
        ]);
      } catch (fetchError) {
        const message = `Error retrieving contact and account information. Will not display status bar. ${fetchError}`;
        console.error('[HEADER]', message);
        return;
      }

      setStats(stats);
    }

    computeStats();
  }, []);

  useEffect(() => {
    async function getTitle() {
      try {
        const settings = await api.getSettings();
        const projectNameSetting = settings.body.settings.find((setting) => setting.list_name === 'project' && setting.item === 'Name');
        setTitle(projectNameSetting.value);
      }
      catch (error) {
        const message = `Error retrieving project settings. Will leave project title as default. ${error}`;
        console.error('[HEADER]', message);
      }
    }

    getTitle();
  }, []);

  let statsMarkup;
  let total = Object.values(stats).reduce((acc, val) => acc + val, 0);
  if (total > 0) {
    statsMarkup = (
      <StatsDisplay
        svgId="headerStatusBar"
        width={400}
        red={stats.red}
        yellow={stats.yellow}
        green={stats.green}
        blue={stats.blue} />
    )
  }

  let headerTitle = <span>Welcome</span>;
  if (title && title !== "") {
    headerTitle = <span>{title}</span>;
  }

  return (
    <div id="content-header">
      <div id="content-header-left">
        {statsMarkup}
      </div>

      <div id="content-header-right">
        <h2 class="content-header-title">{headerTitle}</h2>
        <Switch>
          {/* Advisor */}
          <Route path={`/advisor/import`}>
            <h6 class="content-header-subtitle">Import Data</h6>
          </Route>
          <Route path={`/advisor/editcontacts`}>
            <h6 class="content-header-subtitle">Contacts</h6>
          </Route>
          <Route path={`/advisor/editagreements/advisory`}>
            <h6 class="content-header-subtitle">Financial Accounts | Advisory Financial Accounts</h6>
          </Route>
          <Route path={`/advisor/editagreements/brokerage`}>
            <h6 class="content-header-subtitle">Financial Accounts | Brokerage Financial Accounts</h6>
          </Route>
          <Route path={`/advisor/editagreements/commissionable`}>
            <h6 class="content-header-subtitle">Financial Accounts | Commissionable Financial Accounts</h6>
          </Route>
          <Route path={`/advisor/editagreements/profservices`}>
            <h6 class="content-header-subtitle">Financial Accounts | Professional Services Financial Accounts</h6>
          </Route>
          <Route path={`/advisor/editagreements`}>
            <h6 class="content-header-subtitle">Financial Accounts | Advisory Financial Accounts</h6>
          </Route>
          <Route path={`/advisor`}>
            <h6 class="content-header-subtitle">Dashboard</h6>
          </Route>

          {/* Reviewer */}
          <Route path={`/reviewer/metrics`}>
            <h6 class="content-header-subtitle">QA Metrics</h6>
          </Route>
          <Route path={`/reviewer/contactsreport`}>
            <h6 class="content-header-subtitle">Contacts CAS Report</h6>
          </Route>
          <Route path={`/reviewer/accountsreport`}>
            <h6 class="content-header-subtitle">Financial Accounts CAS Report</h6>
          </Route>
          <Route path={`/reviewer/settings`}>
            <h6 class="content-header-subtitle">Project Settings</h6>
          </Route>
          <Route path={`/reviewer/editcontacts`}>
            <h6 class="content-header-subtitle">Contacts</h6>
          </Route>
          <Route path={`/reviewer/editagreements/advisory`}>
            <h6 class="content-header-subtitle">Financial Accounts | Advisory Financial Accounts</h6>
          </Route>
          <Route path={`/reviewer/editagreements/brokerage`}>
            <h6 class="content-header-subtitle">Financial Accounts | Brokerage Financial Accounts</h6>
          </Route>
          <Route path={`/reviewer/editagreements/commissionable`}>
            <h6 class="content-header-subtitle">Financial Accounts | Commissionable Financial Accounts</h6>
          </Route>
          <Route path={`/reviewer/editagreements/profservices`}>
            <h6 class="content-header-subtitle">Financial Accounts | Professional Services Financial Accounts</h6>
          </Route>
          <Route path={`/reviewer/editagreements`}>
            <h6 class="content-header-subtitle">Financial Accounts | Advisory Financial Accounts</h6>
          </Route>
          <Route path={`/reviewer`}>
            <h6 class="content-header-subtitle">Dashboard</h6>
          </Route>
        </Switch>
      </div>
    </div>
  );
}
