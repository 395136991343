import { useState, useEffect } from "react";

import * as api from "../../../modules/api";
import AccountsReportTable from "./AccountsReportTable/AccountsReportTable";
import LoadingBox from "../../shared/LoadingBox/LoadingBox";

export default function AccountsReport() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accountsReportData, setAccountsReportData] = useState([]);

  useEffect(() => {
    async function getAccountsReport() {
      setLoading(true);
      try {
        const accountData = await api.getAccountsReport();
        if (accountData.ok) {
          setAccountsReportData(accountData.body);
        }
        else {
          throw new Error('Failed to retrieve CAS accounts report');
        }
      }
      catch (error) {
        console.error(error);
        setError(`Error retrieving CAS accounts report`);
        setAccountsReportData([]);
      }

      setLoading(false);
    }

    getAccountsReport();
  }, []);

  if (error) {
    return (
      <div id="report-tables-container">
        <p>{error}</p>
      </div>
    )
  }
  else if (loading) {
    return (
      <LoadingBox />
    )
  }
  else {
    return (
      <div id="report-tables-container">
        <AccountsReportTable data={accountsReportData}/>
      </div>
    );
  }
}
