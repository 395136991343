import { Switch, Route, useRouteMatch } from "react-router-dom";

import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import ImportData from "../../components/HeroSpace/ImportData/ImportData";
import EditContacts from "../../components/HeroSpace/EditContacts/EditContacts";
import EditAgreements from "../../components/HeroSpace/EditAgreements/EditAgreements";
import ProjectDashboard from "../../components/shared/ProjectDashboard/ProjectDashboard";

import "./projects.scss";

/*
  Entrypoint for admin/advisor project(s) work
*/
export default function Projects(props) {
  const match = useRouteMatch();

  return (
    <div id="editor-container">
      <Navbar flags={props.flags} />
      <div id="right-content">
        <Header />
        <div id="editor-space">
          <Switch>
            <Route path={`${match.url}/import`}>
              { props.flags.importData ? <ImportData /> : undefined }
            </Route>
            <Route path={`${match.url}/editcontacts`}>
              <EditContacts flags={props.flags} />
            </Route>
            <Route path={`${match.url}/editagreements/advisory`}>
              <EditAgreements flags={props.flags} tab="advisory" />
            </Route>
            <Route path={`${match.url}/editagreements/brokerage`}>
              <EditAgreements flags={props.flags} tab="brokerage" />
            </Route>
            <Route path={`${match.url}/editagreements/commissionable`}>
              <EditAgreements flags={props.flags} tab="commissionable" />
            </Route>
            <Route path={`${match.url}/editagreements/profservices`}>
              <EditAgreements flags={props.flags} tab="profservices" />
            </Route>
            <Route path={`${match.url}/editagreements`}>
              <EditAgreements flags={props.flags} tab="advisory" />
            </Route>
            <Route path={`${match.url}`}>
              { props.flags.dashboard ? <ProjectDashboard /> : <ImportData /> }
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
