import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';

export default function IdleTimer() {
  const history = useHistory();
  const [isIdle, setIsIdle] = useState(false)
  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)

  const timeout = 1_200_000; //30 minutes
  useIdleTimer({ timeout, onActive: handleOnActive, onIdle: handleOnIdle });

  useEffect(() => {
    if (isIdle === true) {
      localStorage.removeItem('authToken');
      history.push('/');
    }
  }, [isIdle, history]);

  return null;
}
