import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./restore_modal.scss";

export default function RestoreModal(props) {
  let plural = 'record';
  if (props.recordCount > 1) {
    plural = 'records';
  }

  return (
    <AimModal show={props.show}>
      <div id="restore-modal-header">
        <span>Restore</span>
      </div>
      <div id="restore-modal-body">
        <p>You're about to restore {props.recordCount} deleted {plural}. Are you sure you want to continue?</p>
      </div>
      <div id="restore-modal-footer">
      <TableButton icon="fa-arrow-alt-circle-down" text="Restore" onClick={props.onRestore}/>
      <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
      </div>
    </AimModal>
  )
}
