import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
 
const FilePickerModalEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    const KC_BACKSPACE = 8;
    const KC_DELETE = 46;

    // If the user started editing with Backsoace or Delete, just clear the cell.
    // Don't bother showimg the modal.
    if (props.keyPress === KC_BACKSPACE || props.keyPress === KC_DELETE) {
      setValue(null);
      setTimeout(() => props.stopEditing());
    } else {
      props.showFilePickerModal({
        onCancel: () => {
          setTimeout(() => props.stopEditing());
        },
        onClear: () => {
          setValue(null);
          setTimeout(() => props.stopEditing());
        },
        onSelectFile: (file) => {
          setValue(file.upload_id);
          setTimeout(() => props.stopEditing());
        },
      });
    }
  }, [props]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      isPopup() {
        return true;
      }
    };
  });

  //The editor must return _something_ even if the content isn't part of the flow.
  return <div/>;
});

export default FilePickerModalEditor;