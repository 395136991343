import React, { useState } from "react";

import TableButton from "../../TableButton/TableButton";
import AimModal from "../../AimModal/AimModal";

import "./bulk_edit_modal.scss";

export default function BulkEditModal(props) {
  const [editorValues, setEditorValues] = useState([]);
  
  React.useEffect(() => {
    const allowedColumns = props.allColumns.filter(column => column.isBulkEditable());
    const editorValues = allowedColumns.map(column => ({ column, value: null, changed: false }));
    setEditorValues(editorValues);
  }, [props]);


  let plural = 'row';
  if (props.recordCount > 1) {
    plural = 'rows';
  }

  return (
    <AimModal show={props.show}>
      <div id="bulk-edit-modal-header">
        <span>Bulk Edit</span>
      </div>
      <div id="bulk-edit-modal-body">
        <p>Clicking "Save" will overwrite values in the {props.recordCount} selected {plural} with any non-empty values in the inputs below.</p>

        <div id="bulk-edit-modal-body-inputs">
          {editorValues.map((editorValue, i) => {
            let inputMarkup = (
              <input
                key={editorValue.column.path}
                className="bulk-edit-input"
                type="text"
                placeholder="new value..."
                value={editorValue.value}
                onChange={(e) => {
                  setEditorValues(editorValues.map((innerEditorValue, j) => {
                    if (i === j) return ({ column: innerEditorValue.column, value: e.target.value, changed: true });
                    return innerEditorValue;
                  }));
                }} />
            );
            if (editorValue.column.getType() === 'Enumerated List') {
              inputMarkup = (
                <select
                  key={editorValue.column.path}
                  className="bulk-edit-select"
                  value={editorValue.value}
                  onChange={(e) => {
                    setEditorValues(editorValues.map((innerEditorValue, j) => {
                      if (i === j) return ({ column: innerEditorValue.column, value: e.target.value, changed: true });
                      return innerEditorValue;
                    }));
                  }}>
                  {[].concat(editorValue.column.getSelectableValues()).map((selectValue) => <option key={selectValue} value={selectValue}>{selectValue}</option>)}
                </select>
              );
            }

            return (
              <div className="bulk-edit-modal-row">
                <div>{editorValue.column.getLabel()}</div>
                {inputMarkup}
              </div>
            )
          })}
        </div>
      </div>
      <div id="bulk-edit-modal-footer">
        <TableButton
          icon="fa-save" text="Save"
          onClick={() => {
            const editedContent = editorValues.map(state => ({ value: state.value, changed: state.changed, column: state.column.path }));
            const newEditorValues = [];
            for (const editorValue of editorValues) {
              newEditorValues.push({ column: editorValue.column, value: "", changed: false });
            }
            setEditorValues(newEditorValues);
            props.onSave(editedContent);
          }} />
        <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
      </div>
    </AimModal>
  )
}
