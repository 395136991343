import "./loading_box.scss";

export default function LoadingBox(props) {
  let subMessageMarkup;
  if (props.subMessage) {
    subMessageMarkup = (
      <div className="sub-message">
        {props.subMessage}
      </div>
    );
  }

  return (
    <div className="loading-box">
      <i class="fas fa-circle-notch fa-spin"></i>
      <div>
        Loading
      </div>
      {subMessageMarkup}
    </div>
  );
}
