import React from "react";

import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";

import "./alert_modal.scss";

export default function AlertModal(props) {
  return (
    <AimModal show={props.show}>
      <div id="alert-modal-header">
        <span>Alert</span>
      </div>
      <div id="alert-modal-body">
        <p>{props.text}</p>
      </div>
      <div id="alert-modal-footer">
        <TableButton text="Ok" onClick={props.onClose}/>
      </div>
    </AimModal>
  )
}
