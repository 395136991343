import { useState } from "react";
import { Link } from "react-router-dom";

import FileDropZone from "../../shared/FileDropZone/FileDropZone";
import ImportOptionGroup from "./ImportOptionGroup/ImportOptionGroup";

import "./import_data.scss";

export default function ImportData(props) {
  const [activeImport, setActiveImport] = useState(false);
  const [display, setDisplay] = useState('');

  let footerMarkup;
  if (activeImport) {
    footerMarkup = (
      <>
        <div id="import-confirm-message" className="text-muted">
          <p>{display}</p>
        </div>
        <div id="import-confirm-footer-links">
          <Link to={`/advisor/editcontacts`}>View Contacts</Link>
          <Link to={`/advisor/editagreements`}>View Financial Accounts</Link>
        </div>
      </>
    );
  }

  return (
    <div id="import-tools-container">
      <div id="import-file-drop">
        <FileDropZone
          onUpload={(results) => {
            setActiveImport(true);

            if (results.category === "attachment") {
              setDisplay("Attachment uploaded successfully.");
            }
            else if (results.rows < 1) {
              setDisplay("This file contains no new rows.");
            }
            else {
              setDisplay(`This file contains ${results.rows} new rows and ${results.cols} columns. \nNote that records may take up to 30 seconds before they can be viewed in the editor.`);
            }
          }}
          customClasses={["import-data-page-drop-zone"]}
          activeProject={props.activeProject}
          userId={localStorage.getItem('user_id')} />
        <div id="import-footer">
          {footerMarkup}
        </div>
      </div>
      <div id="import-options-container">
        <span id="import-options-container-label" className="text-muted">We can process data from multiple systems</span>

        <div id="import-options-groups">
          {/* Accounting System */}
          <ImportOptionGroup label="Portfolio Accounting Systems">
            <div className="import-option">
              <img src="/images/logos/Tamarac_logo.png" alt="tamarac logo"/>
            </div>
            <div className="import-option">
              <img src="/images/logos/BlackDiamond.png" alt="black diamond logo"/>
            </div>
            <div className="import-option">
              <img src="/images/logos/orion-logo.png" alt="orion logo"/>
            </div>
          </ImportOptionGroup>

          {/* CRM */}
          <ImportOptionGroup label="CRMs">
            <div className="import-option">
              <img src="/images/logos/RedTail.png" alt="red tail logo"/>
            </div>
            <div className="import-option">
              <img src="/images/logos/Junxure.png" alt="junxure logo"/>
            </div>
            <div className="import-option">
              <img src="/images/logos/SalesForce.png" alt="salesforce logo"/>
            </div>
          </ImportOptionGroup>

          {/* Custodian */}
          <ImportOptionGroup label="Custodians">
            <div className="import-option">
              <img src="/images/logos/Schwab.png" alt="schwab logo"/>
            </div>
            <div className="import-option">
              <img src="/images/logos/Fidelity.png" alt="fidelity logo"/>
            </div>
            <div className="import-option">
              <img src="/images/logos/Pershing.png" alt="pershing logo"/>
            </div>
          </ImportOptionGroup>
        </div>
      </div>
    </div>
  );
}
