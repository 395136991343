import LoadingBox from "../../components/shared/LoadingBox/LoadingBox";

export default function Setup() {
  // read query string parameters and shove them into local storage
  const urlParams = new URLSearchParams(window.location.search);

  const authToken = urlParams.get('authToken');
  localStorage.setItem('authToken', authToken);

  if (urlParams.get('isAdmin') === 'true') {
    window.location.replace('/reviewer');
  }
  else {
    window.location.replace('/advisor');
  }

  return (
    <LoadingBox />
  );
}
