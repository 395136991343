import { useEffect, useState } from "react";

import UploadHistoryTable from "../../HeroSpace/UploadHistory/UploadHistoryTable/UploadHistoryTable";
import BigNumberDisplay from "./BigNumberDisplay/BigNumberDisplay";
import * as api from "../../../modules/api";

import "./project_dashboard.scss";

export default function ProjectDashboard(props) {
  const [loadingContactCount, setLoadingContactCount] = useState(false);
  const [contactCount, setContactCount] = useState(0);

  const [loadingFilesUploaded, setLoadingFilesUploaded] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(0);

  const [loadingAccountCount, setLoadingAccountCount] = useState(false);
  const [accountCount, setAccountCount] = useState(0);
  
  const [history, setHistory] = useState([]);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    async function getUploadHistory() {
      setLoadingFilesUploaded(true);

      try {
        const response = await api.getFileHistory();
        if (response.body && Array.isArray(response.body.records)) {
          const accountFiles = response.body.records.filter(file => file.category !== 'attachment');
          const attachmentFiles = response.body.records.filter(file => file.category === 'attachment');
          setHistory(accountFiles);
          setAttachments(attachmentFiles);
          setFilesUploaded(accountFiles.length);
        }
      }
      catch (fetchError) {
        console.error(fetchError);
      }
      
      setLoadingFilesUploaded(false);
    }

    getUploadHistory();
  }, []);

  useEffect(() => {
    async function getDashboardValues() {
      setLoadingContactCount(true);
      setLoadingAccountCount(true);
      try {
        const accounts = await api.getAccounts();
        const contacts = await api.getContacts();
        setContactCount(contacts.length);
        setAccountCount(accounts.length);
      }
      catch (fetchError) {
        console.error(fetchError);
      }
      setLoadingContactCount(false);
      setLoadingAccountCount(false);
    }

    getDashboardValues();
  }, []);

  return (
    <>
      <div id="dashboard-container">
        <div id="dashboard-charts">
          <BigNumberDisplay loading={loadingContactCount} icon="fa-user-circle" count={contactCount} text="Contacts"/>
          <BigNumberDisplay loading={loadingFilesUploaded} icon="fa-file-alt" count={filesUploaded} text="Files Uploaded"/>
          <BigNumberDisplay loading={loadingAccountCount} icon="fa-wallet" count={accountCount} text="Financial Accounts"/>
        </div>
        <div>
          <UploadHistoryTable label="Imported Data Files" data={history} />
          <UploadHistoryTable label="Attachment Files" data={attachments} />
        </div>
      </div>
    </>
  );
}
